import "./AboutIntro.scss";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function AboutIntro() {
  const { t } = useTranslation();
  return (
    <div>
      <section class="key-fig-container">
        <div class="key-figures-container">
            <div class="key-figure">
              <div class="key-fig-wrap">
                <span class="key-fig">50K</span>{" "}
                <span class="key-fig-unit">t/a</span>
                <p>{t("about:intro.1")}</p>
              </div>
            </div>
            <div class="key-figure">
              <div class="key-fig-wrap">
                <span class="key-fig">30M</span>
                <span class="key-fig-unit">&#13217;/a</span>
                <p>{t("about:intro.2")}</p>
              </div>
            </div>
            <div class="key-figure">
              <div class="key-fig-wrap">
                <span class="key-fig">2M</span>
                <span class="key-fig-unit">&#13217;/a</span>
                <p>{t("about:intro.3")}</p>
              </div>
            </div>
            <div class="key-figure key-figure-4">
              <div class="key-fig-wrap">
                <span class="key-fig">10K</span>
                <span class="key-fig-unit"> t/a</span>
                <p>{t("about:intro.4")}</p>
              </div>
            </div>
        </div>
      </section>
    </div>
  );
}
