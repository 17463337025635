import React, { Component } from "react";
import "../contactInfo/ContactInfo.scss";
import { useTranslation, withTranslation } from "react-i18next";
class ContactFormMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
        email: "",
        message: "",
      },
      isSubmitting: false,
      isError: false,
    };
  }

  submitForm = async (e) => {
    e.preventDefault();
    console.log(this.state);
    this.setState({ isSubmitting: true });

    const res = await fetch(
      "https://rnzek4vpr9.execute-api.eu-west-1.amazonaws.com/prod/contact_us",
      {
        method: "POST",
        body: JSON.stringify(this.state.values),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    this.setState({ isSubmitting: false });
    const data = await res.json();
    !data.hasOwnProperty("error")
      ? this.setState({ message: data.success })
      : this.setState({ message: data.error, isError: true });

    setTimeout(
      () =>
        this.setState({
          isError: false,
          message: "",
          values: { name: "", email: "", message: "" },
        }),
      1600
    );
  };

  handleInputChange = (e) =>
    this.setState({
      values: { ...this.state.values, [e.target.name]: e.target.value },
    });
  render() {
    const { t } = this.props;

    return (
      <div>
        <section className="contact-form-map">
          <div className="contact-form-container">
            <div className="contact-form-card">
              <div className="contact-form-header">
                <h3>{t(`contact:Form.title`)}</h3>
                <p>{t(`contact:Form.intro`)}</p>
              </div>
              <form id="contact-form" onSubmit={this.submitForm}>
                <div className="form-input-row">
                  <input
                    type="text"
                    name="name"
                    id="name-input"
                    placeholder={t(`contact:Form.name`)}
                    value={this.state.values.name}
                    onChange={this.handleInputChange}
                    required
                  />
                </div>
                <div className="form-input-row">
                  <input
                    type="email"
                    name="email"
                    id="email-input"
                    placeholder={t(`contact:Form.email`)}
                    value={this.state.values.email}
                    onChange={this.handleInputChange}
                    required
                  />
                </div>
                <div className="form-input-row">
                  <textarea
                    name="message"
                    cols="30"
                    rows="5"
                    placeholder={t(`contact:Form.message`)}
                    id="message-input"
                    value={this.state.values.message}
                    onChange={this.handleInputChange}
                  ></textarea>
                </div>
                <button className="btn1" type="submit">{t(`contact:Form.btn`)}</button>
              </form>
             <div className="submit-wrapper">
             <div className={`message ${this.state.isError && "error"}` }>
                {this.state.isSubmitting ? "Submitting..." : this.state.message}
              </div>
             </div>
            </div>
          </div>
          <div className="contact-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31160.49743578017!2d13.4813593528243!3d-12.512037643862149!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x86907723b24cb43e!2zMTLCsDMxJzMzLjAiUyAxM8KwMjknNTYuNiJF!5e0!3m2!1sen!2set!4v1667566714621!5m2!1sen!2set"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(ContactFormMap);
