import { Helmet } from "react-helmet";
import { ContactInfo } from "../components/contact/contactInfo/ContactInfo";
import Banner from "../components/common/banner/Banner";
import ContactFormMap from "../components/contact/contactFormMap/ContactFormMap";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t(`contact:PageTitle.title`)}</title>
      </Helmet>
      <Banner
        urlBanner="/FactoryImage2.jpg"
        titleBanner={t("contact:banner.title")}
      />
      <ContactInfo />
      <ContactFormMap />
    </div>
  );
}
