import React from "react";
import {Culture} from "../components/about/culture/Culture";
import Banner from "../components/common/banner/Banner";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export const CultureMain = () => {
  const {t} = useTranslation()
  return (
    <div>
      <Helmet><title>{t(`about:culture.PageTitle.title`)}</title></Helmet>
      <Banner
        urlBanner="/CultureBannerNew.jpg"
        titleBanner={t('about:culture.banner.title')}
      />
      <Culture />
    </div>
  );
};
