import { Helmet } from "react-helmet";
export default function Product() {
    return (
        <div>
            <Helmet>
          <title>Product</title>
        </Helmet>
            Product is working 
        </div>
    )
}