import "./AboutBanner.scss"
import img1 from "../../../Images/AboutBannerImg-New.png"
import { useTranslation } from 'react-i18next';
import Fade from "react-reveal/Fade";

export default function AboutBanner() {
  const { t } = useTranslation();
  return (
    <div>
      <section class="about-banner-new">
        <div class="about-banner-container">
          <div class="about-intro">
            <Fade left cascade distance="20% " >
              <div>
                <div class="about-intro-title">{t('about:banner.title.1')}</div>
                <div class="hr-line">
                  <hr/>
                </div>
                <div class="about-title">
                  <h1>{t('about:banner.title.2')}</h1>
                </div>
                <div class="about-text-new">
                  <p>
                  {t('about:banner.text')}
                  </p>
                </div>
              </div>
            </Fade>
          </div>
          <div class="about-banner-img">
            <img src={img1} alt="factory" />
          </div>
        </div>
      </section>
    </div>
  );
}
