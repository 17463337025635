import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";


function SliderContent({ activeIndex, sliderImage }) {

  const {t} = useTranslation()
  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={index === activeIndex ? "slides active" : "inactive"}
        >
          <div className="slider-headline-body">
            <img className="slide-image" src={slide.urls} alt="" />
                <div className="hero-content">
                  <div className="hero-content-main-wrapper">
                    <Fade left cascade distance="10%" >
                      <div className="hero-content-wrapper">
                        <h1 className="first-title">{slide.title1}</h1>
                        <div className="hero-btn">
                          {/* <Link to="/Product" className="btn1">
                            {t("BannerHome.10")}
                          </Link> */}
                          <Link to="/PlasterBoard" className="btn1 btn-light1">
                          {t('btn.1')}
                          </Link>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
          </div>
        </div>
      ))}
    </section>
  );
}

export default SliderContent;