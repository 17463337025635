import "./Navbar.scss";
import logo from "../../../Images/logo.svg";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { RiMenu3Fill, RiCloseFill } from "react-icons/ri";
import { FiMenu } from "react-icons/fi"
import { CgClose} from "react-icons/cg"
import { GrClose } from "react-icons/gr";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';


export default function Navbar() {

  function goToTop() {
    window.scrollTo(0, 0);
  }
  const { t, i18n } = useTranslation();
  function clickLanguage(lang) {
    i18n.changeLanguage(lang)
  }

  const [state, setState] = useState({
    showAbout: false,
    showProduct: false,
    showLang: false,
    showNavItems: false
  });

  function toggle() {
    setState((prevState) => {
      return {
        showNavItems: !prevState.showNavItems
      };
    });
    console.log(state.showNavItems);
  }

  function toggleAboutDropdown() {
    setState((prevState) => {
      return {
        showAbout: !prevState.showAbout,
        showNavItems: true
      };
    });
  }

  function toggleProductDropdown() {
    setState((prevState) => {
      return {
        showProduct: !prevState.showProduct,
        showNavItems: true
      };
    });
    }
    
    function toggleLangDropdown() {
        setState((prevState) => {
          return {
            showLang: !prevState.showLang,
            showNavItems: false
          };
        });
      }

  function hideNavItems() {
    setState((prevState) => {
      return {
        showProduct: false,
        showAbout: false,
        showNavItems: false,
        showLang: false
      };
    });
  }

  return (
    <div className="navbar">
      <div className="navbar--container" onMouseLeave={hideNavItems}>
        <div className="navbar-logo--wrapper">
          <NavLink to="/" onClick={goToTop}><img className="navbar--logo" src={logo} alt="logo" /></NavLink>
        </div>
        <div
          className={
            state.showNavItems
              ? "navbar--items navbar-items--show"
              : "navbar--items"
          }
        >
          <div
            onClick={() => setState({ showNavItems: false })}
            className="navbar--item"
          >
            <NavLink to="/" onClick={goToTop}>{t('navlinks.1')}</NavLink>
          </div>
          <div
            className="navbar-item--container"
            // onClick={() =>
            //   setState({ showAbout: true, showNavItems: true })
            // }
          >
            <div className="navbar--item" onClick={toggleAboutDropdown}>
              <span>{t('navlinks.2')}</span>{" "}
              <span className="navbar--chevron">
                {state.showAbout ? <BsChevronUp /> : <BsChevronDown />}
              </span>
            </div>
            <div
              className={
                state.showAbout
                  ? "navbar-item--dropdown navbar-item-dropdown--show"
                  : "navbar-item--dropdown"
              }
            >
              <NavLink to="/About" onClick={goToTop}><div onClick={() => setState({ showNavItems: false })} className="navbar-item--list navbar-item-list-1st">{t('navlinks.3')}</div></NavLink>
              <NavLink to="/Culture" onClick={goToTop}><div onClick={() => setState({ showNavItems: false })} className="navbar-item--list">{t('navlinks.4')}</div></NavLink>
              <NavLink to="/Sustainability" onClick={goToTop}><div onClick={() => setState({ showNavItems: false })}  className="navbar-item--list">{t('navlinks.5')}</div></NavLink>
            </div>
          </div>
          <div
            className="navbar-item--container"
            // onClick={() =>
            //   setState({ showProduct: true, showNavItems: true })
            // }
          >
            <div className="navbar--item" onClick={toggleProductDropdown}>
              <span>{t('navlinks.6')}</span>{" "}
              <span className="navbar--chevron">
                {state.showProduct ? <BsChevronUp /> : <BsChevronDown />}
              </span>
            </div>
            <div
              className={
                state.showProduct
                  ? "navbar-item--dropdown navbar-item-dropdown--show"
                  : "navbar-item--dropdown"
              }
            >
              <NavLink to="/PlasterBoard" onClick={goToTop}><div className="navbar-item--list navbar-item-list-1st" onClick={() => setState({ showNavItems: false })} >{t('navlinks.7')}</div></NavLink>
             <NavLink to="/Powder" onClick={goToTop}> <div onClick={() => setState({ showNavItems: false })}  className="navbar-item--list">{t('navlinks.8')}</div></NavLink>
              <NavLink to="/SteelKeel" onClick={goToTop}><div onClick={() => setState({ showNavItems: false })}  className="navbar-item--list">{t('navlinks.9')}</div></NavLink>
              <NavLink to="/PlasterBlock" onClick={goToTop}><div onClick={() => setState({ showNavItems: false })}  className="navbar-item--list">{t('navlinks.10')}</div></NavLink>
            </div>
          </div>
          <div onClick={() => setState({ showNavItems: false })}  className="navbar--item"><NavLink to="/Contact" onClick={goToTop}>{t('navlinks.11')}</NavLink></div>
        </div>
        <div className="navbar-item--container-wrapper">
          <div
            className="navbar-item-container--lang"
            onClick={toggleLangDropdown}
          >
            <div className="navbar-lang--bar"></div>
            <div className="navbar--lang">
              {t('navlinks.12')}{" "}
              <span className="navbar--chevron">
                {state.showLang ? <BsChevronUp /> : <BsChevronDown />}
              </span>
            </div>
            <div
              className={
                state.showLang
                  ? "navbar-item-dropdown--lang navbar-item-dropdown--show"
                  : "navbar-item-dropdown--lang"
              }
            >
              <div onClick={() => clickLanguage('cn')} style={{ color: i18n.resolvedLanguage === 'cn' ? '#CD151D' : '' }} className="navbar-item--list navbar-item-list-1st">中文</div>
              <div onClick={() => clickLanguage('en')} style={{ color: i18n.resolvedLanguage === 'en' ? '#CD151D' : '' }}className="navbar-item--list">ENGLISH</div>
              <div onClick={() => clickLanguage('pt')} style={{ color: i18n.resolvedLanguage === 'pt' ? '#CD151D' : '' }}className="navbar-item--list">PORTUGUÊS</div>
            </div>
          </div>
          <div
            className={
              state.showNavItems
                ? "navbar--menu navbar-menu--show"
                : "navbar--menu"
            }
            onClick={toggle}
          >
            {!state.showNavItems && (
              <div>
                <FiMenu  />
              </div>
            )}
            {state.showNavItems && (
              <div className="navbar-menu--close">
                <CgClose  />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
