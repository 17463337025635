import React from "react";
import "./Sustainability.scss";
import Renewable from "../../../Images/RenewableEnergy.jpg";
import { FaLeaf } from "react-icons/fa";
import { MdWaterDrop } from "react-icons/md";
import { SlChemistry } from "react-icons/sl";
import { GiEcology } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export const Sustainabilitycp = () => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="sustainability-container">
        <Fade bottom distance="20%">
          <div className="sustain-title">
            <h2>
              {t("about:sustainability.main.title.1")}
              <span className="sustain-title-gray">
                {" "}
                | {t("about:sustainability.main.title.2")}
              </span>
            </h2>
          </div>
        </Fade>
        <div className="sustain-wrapper">
          <div className="sustain-highlight">
            <Fade bottom cascade distance="50%" >
              <div>
                <div className="sustain-one">
                  <span className="sustain-highlight-icon">
                    <FaLeaf className="sustain-svg" size={32} color="#8DC63F" />
                  </span>
                  <h4> {t("about:sustainability.main.left.1")}</h4>
                </div>
                <div className="sustain-one">
                  <span className="sustain-highlight-icon">
                    <SlChemistry
                      className="sustain-svg"
                      size={32}
                      color="#8DC63F"
                    />
                  </span>
                  <h4> {t("about:sustainability.main.left.2")}</h4>
                </div>
                <div className="sustain-one">
                  <span className="sustain-highlight-icon">
                    <MdWaterDrop
                      className="sustain-svg"
                      size={32}
                      color="#8DC63F"
                    />
                  </span>
                  <h4> {t("about:sustainability.main.left.3")}</h4>
                </div>
                <div className="sustain-one">
                  <span className="sustain-highlight-icon">
                    <GiEcology
                      className="sustain-svg"
                      size={32}
                      color="#8DC63F"
                    />
                  </span>
                  <h4> {t("about:sustainability.main.left.4")}</h4>
                </div>
              </div>
            </Fade>
          </div>
          <Fade bottom distance="20%">
            <div className="sustain-image">
              <img src={Renewable} alt="" />
            </div>
          </Fade>
          <Fade bottom distance="20%">
            <div className="sustain-content">
              <h3 className="sustain-intro">
                {t("about:sustainability.main.right.title")}
              </h3>
              <div className="sustain-intro-text">
                <p>{t("about:sustainability.main.right.text")}</p>
              </div>
            </div>
          </Fade>
        </div>
      </section>
    </div>
  );
};
