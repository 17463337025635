import React from "react";
import "./GypsumBoard.scss";
import GypsumBoardImg from "../../../Images/PlasterBoard1.jpg";
import GypsumBoardBanner from "../../../Images/gypsum-banner-img.png";
import PlasterBoardFire from "../../../Images/PlasterBoardFire.jpg";
import PlasterBoardHumid from "../../../Images/PlasterBoardHumid.jpg";
import PlasterWaterResist from "../../../Images/WaterResist.png";
import product5 from "../../../Images/gypsum-product5-img1.png";
import { useTranslation } from "react-i18next";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import Fade from "react-reveal/Fade";

export const GypsumBoard = () => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="gypsum-banner-container">
        <div className="gypsum-banner">
          <div className="gypsum-banner-content">
            <Fade left cascade distance="20%">
              <div>
                <p className="gypsum-banner-text">
                  {t("product:gypsumboard.banner.title.1")}
                </p>
                <hr className="gyspum-banner-hr" />
                <h1 className="gypsum-banner-title">
                  {t("product:gypsumboard.banner.title.2")}
                </h1>
                <Fade left cascade distance="10%">
                  <ul className="gypsum-banner-list">
                    <li>{t("product:gypsumboard.banner.text.1")}</li>
                    <li>{t("product:gypsumboard.banner.text.2")}</li>
                    <li>{t("product:gypsumboard.banner.text.3")}</li>
                    <li>{t("product:gypsumboard.banner.text.4")}</li>
                    <li>{t("product:gypsumboard.banner.text.5")}</li>
                  </ul>
                </Fade>
              </div>
            </Fade>
          </div>
          <img
            className="gypsum-banner-img"
            src={GypsumBoardBanner}
            alt="gypsum banner"
          />
        </div>
      </section>

      <section className="gypsum-product-sec gypsum-product-sec-1">
        <div class="gypsum-product-detail">
          <div class="gypsum-product-detail-content">
            <Fade left distance="10%">
              <div>
                <h2>{t("product:gypsumboard.product1.title")}</h2>
                <p> {t("product:gypsumboard.product1.text")}</p>
                <h4>{t("product:gypsumboard.spec.title")}</h4>
                <p>
                  <span>{t("product:gypsumboard.spec.text.1")}</span> 2000-3000
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.2")}</span> 1200-1220
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.3")}</span> 9.5 / 12.5
                  / 13 / 15 / 18 / 20
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.4")}</span> 6,5 / 8,2
                  / 8,5 / 9,4 / 11,3 / 12,6
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.5")}</span>{" "}
                  {t("product:gypsumboard.spec.value.1")}
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.6")}</span> EN520
                </p>
                <h4>{t("product:gypsumboard.spec.doc.title")}</h4>
                <p class="pdf-link">
                  <a
                    className="product-doc--link"
                    href="ordinary_drywall.pdf"
                    target="blank"
                  >
                    {" "}
                    <span className="pdf--icon">
                      <BsFileEarmarkPdfFill color="#CD151D" />
                    </span>
                    <span>{t("product:gypsumboard.spec.doc.text")}</span>
                  </a>
                </p>
              </div>
            </Fade>
          </div>
          <div class="gypsum-product-detail-img-container">
            <img class="mySlides-img" src={GypsumBoardImg} />
            <br />
          </div>
        </div>
      </section>

      <section className="gypsum-product-sec">
        <div className="gypsum-product-detail">
          <div className="gypsum-product-detail-content">
            <Fade left distance="10%">
              <div>
                <h2>{t("product:gypsumboard.product2.title")}</h2>
                <p> {t("product:gypsumboard.product2.text")}</p>
                <h4>{t("product:gypsumboard.spec.title")}</h4>
                <p>
                  <span>{t("product:gypsumboard.spec.text.1")}</span> 2000-3000
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.2")}</span> 1200-1220
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.3")}</span> 9.5 / 12.5
                  / 13 / 15 / 18 / 20
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.4")}</span> 6,5 / 8,2
                  / 8,5 / 9,4 / 11,3 / 12,6
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.5")}</span>{" "}
                  {t("product:gypsumboard.spec.value.1")}
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.6")}</span> EN520
                </p>
                <h4>{t("product:gypsumboard.spec.doc.title")}</h4>
                <p className="pdf-link">
                  <a
                    className="product-doc--link"
                    href="moisture-proof_drywall.pdf"
                    target="blank"
                  >
                    <span className="pdf--icon">
                      <BsFileEarmarkPdfFill color="#CD151D" />
                    </span>
                    <span>{t("product:gypsumboard.spec.doc.text")}</span>
                  </a>
                </p>
              </div>
            </Fade>
          </div>
          <div className="gypsum-product-detail-img-container">
            <img className="mySlides-img" src={PlasterBoardHumid} />

            <br />
          </div>
        </div>
      </section>

      <section className="gypsum-product-sec">
        <div className="gypsum-product-detail">
          <div className="gypsum-product-detail-content">
            <Fade left distance="10%">
              <div>
                <h2>{t("product:gypsumboard.product3.title")}</h2>
                <p> {t("product:gypsumboard.product3.text")}</p>
                <h4>{t("product:gypsumboard.spec.title")}</h4>
                <p>
                  <span>{t("product:gypsumboard.spec.text.1")}</span> 2000-3000
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.2")}</span> 1200-1220
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.3")}</span> 9.5 / 12.5
                  / 13 / 15 / 18 / 20
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.4")}</span> 6,5 / 8,2
                  / 8,5 / 9,4 / 11,3 / 12,6
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.5")}</span>{" "}
                  {t("product:gypsumboard.spec.value.1")}
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.6")}</span> EN520
                </p>
                <h4>{t("product:gypsumboard.spec.doc.title")}</h4>
                <p className="pdf-link">
                  <a
                    className="product-doc--link"
                    href="fire-resistant_drywall.pdf"
                    target="blank"
                  >
                    {" "}
                    <span className="pdf--icon">
                      <BsFileEarmarkPdfFill color="#CD151D" />
                    </span>
                    <span>{t("product:gypsumboard.spec.doc.text")}</span>
                  </a>
                </p>
              </div>
            </Fade>
          </div>
          <div className="gypsum-product-detail-img-container">
            <img className="mySlides-img" src={PlasterBoardFire} />

            <br />
          </div>
        </div>
      </section>

      <section className="gypsum-product-sec">
        <div class="gypsum-product-detail">
          <div class="gypsum-product-detail-content">
            <Fade left distance="10%">
              <div>
                <h2>{t("product:gypsumboard.product4.title")}</h2>
                <p>{t("product:gypsumboard.product4.text")}</p>
                <h4>{t("product:gypsumboard.spec.title")}</h4>
                <p>
                  <span>{t("product:gypsumboard.spec.text.1")}</span> 2000-3000
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.2")}</span> 1200-1220
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.3")}</span> 9.5 / 12.5
                  / 13 / 15 / 18 / 20
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.4")}</span> 6,5 / 8,2
                  / 8,5 / 9,4 / 11,3 / 12,6
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.5")}</span>{" "}
                  {t("product:gypsumboard.spec.value.1")}
                </p>
                <p>
                  <span>{t("product:gypsumboard.spec.text.6")}</span> EN520
                </p>
                <h4>{t("product:gypsumboard.spec.doc.title")}</h4>
                <p class="pdf-link">
                  <a
                    className="product-doc--link"
                    href="water-resistant_drywall.pdf"
                    target="blank"
                  >
                    {" "}
                    <span className="pdf--icon">
                      <BsFileEarmarkPdfFill color="#CD151D" />
                    </span>
                    <span>{t("product:gypsumboard.spec.doc.text")}</span>
                  </a>
                </p>
              </div>
            </Fade>
          </div>{" "}
          <div class="gypsum-product-detail-img-container">
            <img class="mySlides-img" src={PlasterWaterResist} />

            <br />
          </div>
        </div>
      </section>

      <section className="gypsum-product-sec">
        <div class="gypsum-product-detail">
          <div class="gypsum-product-detail-content">
            <Fade left distance="10%">
              <h2>{t("product:gypsumboard.product5.title")}</h2>
              <p>{t("product:gypsumboard.product5.text")}</p>
              <h4>{t("product:gypsumboard.spec.title")}</h4>
              <p>
                <span>{t("product:gypsumboard.spec.text.1")}</span> 595
              </p>
              <p>
                <span>{t("product:gypsumboard.spec.text.2")}</span> 595
              </p>
              <p>
                <span>{t("product:gypsumboard.spec.text.3")}</span> 9mm
              </p>
              <p>
                <span>{t("product:gypsumboard.spec.text.5")}</span>{" "}
                {t("product:gypsumboard.spec.value.2")}
              </p>
              <p>
                <span>{t("product:gypsumboard.spec.text.7")}</span> 238 / 244 /
                245 / 256
              </p>
              <h4>{t("product:gypsumboard.spec.doc.title")}</h4>
              <p class="pdf-link">
                <a
                  className="product-doc--link"
                  href="coated_plasterboard.pdf"
                  target="blank"
                >
                  {" "}
                  <span className="pdf--icon">
                    <BsFileEarmarkPdfFill color="#CD151D" />
                  </span>
                  <span>{t("product:gypsumboard.spec.doc.text")}</span>
                </a>
              </p>
            </Fade>
          </div>{" "}
          <div class="gypsum-product-detail-img-container">
            <img class="mySlides-img" src={product5} />

            <br />
          </div>
        </div>
      </section>
    </div>
  );
};
