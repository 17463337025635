import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "./components/common/navbar/Navbar";
import Home from "./routes/home";
import About from "./routes/about";
import Product from "./routes/product";
import Contact from "./routes/contact";
import { Footer } from "./components/common/footer/Footer";
import {SustainabilityMain} from "./routes/SustainabilityMain";
import {PlasterBoard} from "./routes/PlasterBoard"
import { Powder } from "./routes/Powder";
import {PlasterBlock} from "./routes/PlasterBlock"
import { SteelKeel } from "./routes/SteelKeel";
import { CultureMain } from "./routes/cultureMain";


export default function App() {
  const { i18n } = useTranslation()
  return (
    <div id={ i18n.resolvedLanguage  === 'cn' ?  'cn-lang': 'pt-lang'}>
      <Navbar />
       <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Culture" element={<CultureMain/>} />
        <Route path="/Sustainability" element={<SustainabilityMain/>} />
        <Route path="/Product" element={<Product />} />
        <Route path="/PlasterBoard" element={<PlasterBoard />}   />
        <Route path="/Powder" element={<Powder/>} />
        <Route path="/PlasterBlock" element={<PlasterBlock/>} />
        <Route path="/SteelKeel" element={<SteelKeel/>} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

