import React from 'react'
import { LightSteel } from '../components/product/lightSteel/LightSteel'
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const SteelKeel = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet><title>{t(`product:lightsteel.PageTitle.title`)}</title></Helmet>
        <LightSteel />
    </div>
  )
}
