import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import common from "./locales/cn/common.json"
import home from "./locales/cn/home.json"
import about from "./locales/cn/about.json"
import product from "./locales/cn/product.json"
import contact from "./locales/cn/contact.json"
import commonPT from "./locales/pt/common.json"
import homePT from "./locales/pt/home.json"
import aboutPT from "./locales/pt/about.json"
import productPT from "./locales/pt/product.json"
import contactPT from "./locales/pt/contact.json"
import commonEN from "./locales/en/common.json"
import homeEN from "./locales/en/home.json"
import aboutEN from "./locales/en/about.json"
import productEN from "./locales/en/product.json"
import contactEN from "./locales/en/contact.json"


const languages = ['pt', 'cn', 'en'];

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'pt',
    whitelist: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    partialBundledLanguages: true,
    ns: ['common'],
    resources: {
      'cn': {
        common,
        home,
        about,
        product,
        contact
     },
      'pt': {
        common: commonPT,
        home: homePT,
        about: aboutPT,
        product: productPT,
        contact: contactPT
      },
      'en': {
        common: commonEN,
        home: homeEN,
        about: aboutEN,
        product: productEN,
        contact: contactEN
      },
      
    }

  
   
  });

 

export default i18n;