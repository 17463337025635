import React from "react";
import GypsumPowderImg from "../../../Images/gypsum-powder-img1.jpg";
import "./GypsumPowder.scss";
import { useTranslation } from "react-i18next";
import img1 from "../../../Images/gypsum-powder-banner-img.png";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import Fade from "react-reveal/Fade";

export const GypsumPowder = () => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="gypsum-banner-container">
        <div className="gypsum-banner">
          <div className="gypsum-banner-content">
            <Fade left distance="10%">
              <div>
                <p className="gypsum-banner-text">
                  {t("product:gypsumpowder.banner.title")}
                </p>
                <hr className="gyspum-banner-hr" />
                <h1 className="gypsum-banner-title">
                  {t("product:gypsumpowder.banner.text")}
                </h1>
              </div>
            </Fade>
          </div>
          <img className="gypsum-banner-img1" src={img1} alt="gypsum banner" />
        </div>
      </section>

      <section className="gypsum-product-sec">
        <div className="gypsum-product-detail">
          <div className="gypsum-product-detail-content">
            <Fade left distance="10%">
              <div>
                <h2>{t("product:gypsumpowder.product.title")}</h2>
                <p> {t("product:gypsumpowder.product.text")}</p>
                <h4>{t("product:doc.title")}</h4>
                <p className="pdf-link">
                  <a
                    className="product-doc--link"
                    href="plaster_powder.pdf"
                    target="blank"
                  >
                    <span className="pdf--icon">
                      <BsFileEarmarkPdfFill color="#CD151D" />
                    </span>
                    <span>{t("product:gypsumboard.spec.doc.text")}</span>
                  </a>
                </p>
              </div>
            </Fade>
          </div>
          <div className="gypsum-product-detail-img-container">
            <Fade right distance="10%">
              <img className="mySlides-img" src={GypsumPowderImg} />
            </Fade>
          </div>
        </div>
      </section>
    </div>
  );
};
