import "./MissionVision.scss";
import icon1 from "./MissionIcon.png";
import icon2 from "./VisionIcon4.png";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function MissionVision() {
  const { t } = useTranslation();
  return (
    <div>
      <section class="vision-about">
        <div class="vision-card-contanier reveal">
          <div class="vision-card-wrapper">
            <div class="mission-card">
              <Fade bottom distance="20%">
                <div>
                  <img src={icon1} alt="" />
                  <h3>{t("about:mission.title")}</h3>
                  <p>{t("about:mission.text")}</p>
                </div>
              </Fade>
            </div>

            <div class="vision-card">
              
              <Fade bottom distance="20%">
                <div>
                  <img src={icon2} alt="" />
                  <h3>{t("about:vision.title")}</h3>
                  <p>{t("about:vision.text")}</p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <div class="vison-about-overlay"></div>
      </section>
    </div>
  );
}
