import React from "react";
import KeelBanner from "../../../Images/light-steel-banner-img.png";
import KeelImg1 from "../../../Images/light-steel-img1.png";
import KeelImg3 from "../../../Images/light-steel3.png";
import KeelImg4 from "../../../Images/light-steel4.png";
import KeelImg5 from "../../../Images/light-steel5.png";
import KeelImg6 from "../../../Images/light-steel-img6.png";
import KeelImg7 from "../../../Images/light-steel-img7.png";
import { useTranslation } from "react-i18next";
import "../gypsumBoard/GypsumBoard.scss";
import "./LightSteel.scss";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import Fade from "react-reveal/Fade";

export const LightSteel = () => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="gypsum-banner-container">
        <div className="gypsum-banner">
          <div className="gypsum-banner-content">
            <Fade left cascade distance="20%">
              <div>
                <p className="gypsum-banner-text">
                  {t("product:lightsteel.banner.title")}
                </p>
                <hr className="gyspum-banner-hr" />
                <h1 className="gypsum-banner-title">
                  {t("product:lightsteel.banner.text")}
                </h1>
              </div>
            </Fade>
          </div>
          <img
            className="gypsum-banner-img"
            src={KeelBanner}
            alt="gypsum banner"
          />
        </div>
      </section>
      
      <section className="gypsum-product-steel-sec-wrapper">
        <div className="gypsum-product-steel-sec">
          <div className="gypsum-product-steel">
            <Fade bottom distance="10%">
              <div>
                <div className="gypsum-product-detail-content">
                  <h2>{t("product:lightsteel.product1.title")}</h2>
                  <p>{t("product:doc.spec")} QT-T24-28, 19kw</p>
                  <h4>{t("product:doc.title")}</h4>
                  <p className="pdf-link">
                    <a
                      className="product-doc--link"
                      href="T-shaped_sub_joist.pdf"
                      target="blank"
                    >
                      <span className="pdf--icon">
                        <BsFileEarmarkPdfFill color="#CD151D" />
                      </span>
                      <span>{t("product:gypsumboard.spec.doc.text")}</span>
                    </a>
                  </p>
                </div>
                <div className="gypsum-product-steel-img-container">
                  <img className="light-steel-img" src={KeelImg1} />
                </div>
              </div>
            </Fade>
          </div>
          <div className="gypsum-product-steel">
            <Fade bottom distance="12%">
              <div>
                <div className="gypsum-product-detail-content">
                  <h2>{t("product:lightsteel.product2.title")}</h2>
                  <p>{t("product:doc.spec")} QT-DG50/200-40,29.5kw</p>
                  <h4>{t("product:doc.title")}</h4>
                  <p className="pdf-link">
                    <a
                      className="product-doc--link"
                      href="variable_vertical_joist.pdf"
                      target="blank"
                    >
                      <span className="pdf--icon">
                        <BsFileEarmarkPdfFill color="#CD151D" />
                      </span>
                      <span>{t("product:gypsumboard.spec.doc.text")}</span>
                    </a>
                  </p>
                </div>
                <div className="gypsum-product-steel-img-container">
                  <img className="light-steel-img" src={KeelBanner} />
                </div>
              </div>
            </Fade>
          </div>
          <div className="gypsum-product-steel">
            <Fade bottom distance="14%">
              <div>
                <div className="gypsum-product-detail-content">
                  <h2>{t("product:lightsteel.product3.title")}</h2>
                  {/*s<p>Padrão Nacional: GB/T 1839-2003, GB/T 2518-2019, GB/T 6739-2006, GB/T 12754-2019, GB/T25052-2</p> */}
                  <h4>{t("product:doc.title")}</h4>
                  <p className="pdf-link">
                    <a
                      className="product-doc--link"
                      href="variable_ground_joist.pdf"
                      target="blank"
                    >
                      <span className="pdf--icon">
                        <BsFileEarmarkPdfFill color="#CD151D" />
                      </span>
                      <span>{t("product:gypsumboard.spec.doc.text")}</span>
                    </a>
                  </p>
                </div>
                <div className="gypsum-product-steel-img-container">
                  <img className="light-steel-img" src={KeelImg3} />
                </div>
              </div>
            </Fade>
          </div>
          <div className="gypsum-product-steel">
            <Fade bottom distance="10%">
              <div>
                <div className="gypsum-product-detail-content">
                  <h2>{t("product:lightsteel.product4.title")}</h2>
                  <p>{t("product:doc.spec")} QT-U38-15,20kw</p>
                  <h4>{t("product:doc.title")}</h4>
                  <p className="pdf-link">
                    <a
                      className="product-doc--link"
                      href="steel_belt_for_38_main_joist.pdf"
                      target="blank"
                    >
                      <span className="pdf--icon">
                        <BsFileEarmarkPdfFill color="#CD151D" />
                      </span>
                      <span>{t("product:gypsumboard.spec.doc.text")}</span>
                    </a>
                  </p>
                </div>
                <div className="gypsum-product-steel-img-container">
                  <img className="light-steel-img" src={KeelImg4} />
                </div>
              </div>
            </Fade>
          </div>
          <div className="gypsum-product-steel">
            <Fade bottom distance="12%">
              <div>
                <div className="gypsum-product-detail-content">
                  <h2>{t("product:lightsteel.product5.title")}</h2>
                  <p>{t("product:doc.spec")} QT-FG49-19,24kw</p>
                  <h4>{t("product:doc.title")}</h4>
                  <p className="pdf-link">
                    <a
                      className="product-doc--link"
                      href="steel_belts_for_50_sub_joist.pdf"
                      target="blank"
                    >
                      <span className="pdf--icon">
                        <BsFileEarmarkPdfFill color="#CD151D" />
                      </span>
                      <span>{t("product:gypsumboard.spec.doc.text")}</span>
                    </a>
                  </p>
                </div>
                <div className="gypsum-product-detail-img-container">
                  <img className="light-steel-img" src={KeelImg5} />
                </div>
              </div>
            </Fade>
          </div>
          <div className="gypsum-product-steel">
            <Fade bottom distance="14%">
              <div>
                <div className="gypsum-product-detail-content">
                  <h2>{t("product:lightsteel.product6.title")}</h2>
                  <p>{t("product:doc.spec")} QT-KG20-38,22kw</p>
                  <h4>{t("product:doc.title")}</h4>
                  <p className="pdf-link">
                    <a
                      className="product-doc--link"
                      href="steel_belt_for_card_type_joist.pdf"
                      target="blank"
                    >
                      <span className="pdf--icon">
                        <BsFileEarmarkPdfFill color="#CD151D" />
                      </span>
                      <span>{t("product:gypsumboard.spec.doc.text")}</span>
                    </a>
                  </p>
                </div>
                <div className="gypsum-product-detail-img-container">
                  <img className="light-steel-img" src={KeelImg6} />
                </div>
              </div>
            </Fade>
          </div>
          <div className="gypsum-product-steel">
            <Fade bottom distance="10%">
              <div>
                <div className="gypsum-product-detail-content">
                  <h2>{t("product:lightsteel.product7.title")}</h2>
                  <p>{t("product:doc.spec")} QT-BJ24-24,14kw</p>
                  <h4>{t("product:doc.title")}</h4>
                  <p className="pdf-link">
                    <a
                      className="product-doc--link"
                      href="steel_belt_for_corners.pdf"
                      target="blank"
                    >
                      <span className="pdf--icon">
                        <BsFileEarmarkPdfFill color="#CD151D" />
                      </span>
                      <span>{t("product:gypsumboard.spec.doc.text")}</span>
                    </a>
                  </p>
                </div>
                <div className="gypsum-product-detail-img-container">
                  <img className="light-steel-img" src={KeelImg7} />
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </div>
  );
};
