import "./PromoAbout.scss";
import img1 from "../../../Images/Key-FigImg.jpg";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function PromoAbout() {
  const { t } = useTranslation();
  return (
    <div>
      <section class="key-figures ">
        <div class="key-figures-wrappper">
         
            
              {" "}
              <div class="key-fig-header">
                <h2 class="key-fig-sub-title">
                  REDACACIA{" "}
                  <span class="key-fig-gray-title">
                    | {t("about:promo.title")}
                  </span>
                </h2>
                <p class="key-fig-sub-headline">{t("about:promo.text")}</p>
              </div>
          <div class="key-fig-header-image">
            <img src={img1} alt="" />
          </div>
        </div>
      </section>
    </div>
  );
}
