import React from "react";
import "./ContactInfo.scss";
import {
  AO,
  CD,
  CG,
  CI,
  GH,
  NG,
  ZA,
  MZ,
  NA,
} from "country-flag-icons/react/3x2";
import { MdPhoneEnabled, MdEmail, MdPerson } from "react-icons/md";
import { FcGlobe } from "react-icons/fc";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export const ContactInfo = () => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="contact-card-container">
        <Fade bottom distance="20%">
          <div className="contact-locations">
            <h2>
            {t(`contact:Intro.title`)}
              <span className="contact-header-gray">
                {" "}
                | {t(`contact:Intro.title1`)}
              </span>
            </h2>
            <p className="contact-text">{t(`contact:Intro.text`)}</p>
          </div>
        </Fade>
        <div className="card-wrapper">
          <Fade bottom cascade distance="20%">
            <div>
              <div className="contact-card-row1">
                <div className="contact-card" id="card1-border">
                  <div className="card-content">
                    <h4 className="flag-country-wrapper">
                      <span>
                        <AO title="Angola" classNameName="flag-icon" />
                      </span>
                      {t(`contact:Card1.country`)}
                    </h4>
                    <p className="company-name">{t(`contact:Card1.company`)}</p>
                    <hr className="contact-card-hr" />
                    <div className="phone">
                      <span>
                        <MdPhoneEnabled />
                      </span>
                      <p>{t(`contact:Card1.phone`)}</p>
                    </div>
                    <div className="email">
                      <span>
                        <MdEmail />
                      </span>
                      <p>{t(`contact:Card1.email`)}</p>
                    </div>
                    <div className="contact-person">
                      <span>
                        <MdPerson />
                      </span>
                      <p>{t(`contact:Card1.contact`)}</p>
                    </div>
                  </div>
                </div>
                <div className="contact-card" id="card2-border">
                  <div className="card-content">
                    <h4>
                      <span>
                        {" "}
                        <CD title="DRC" classNameName="flag-icon" />
                      </span>{" "}
                      {t(`contact:Card2.country`)}
                    </h4>
                    <p className="company-name">{t(`contact:Card2.company`)}</p>
                    <hr className="contact-card-hr" />
                    <div className="phone">
                      <span>
                        <MdPhoneEnabled />
                      </span>
                      <p>{t(`contact:Card2.phone`)}</p>
                    </div>
                    <div className="email">
                      <span>
                        <MdEmail />
                      </span>
                      <p>{t(`contact:Card2.email`)}</p>
                    </div>
                    <div className="contact-person">
                      <span>
                        <MdPerson />
                      </span>
                      <p>{t(`contact:Card2.contact`)}</p>
                    </div>
                  </div>
                </div>
                <div className="contact-card" id="card3-border">
                  <div className="card-content">
                    <h4>
                      <span>
                        {" "}
                        <CG title="Brazzaville" classNameName="flag-icon" />
                      </span>{" "}
                      {t(`contact:Card3.country`)}
                    </h4>
                    <p className="company-name">
                      {t(`contact:Card3.company`)}{" "}
                    </p>
                    <hr className="contact-card-hr" />
                    <div className="phone">
                      <span>
                        <MdPhoneEnabled />
                      </span>
                      <p>{t(`contact:Card3.phone`)}</p>
                    </div>
                    <div className="email">
                      <span>
                        <MdEmail />
                      </span>
                      <p>{t(`contact:Card3.email`)}</p>
                    </div>
                    <div className="contact-person">
                      <span>
                        <MdPerson />
                      </span>
                      <p>{t(`contact:Card3.contact`)}</p>
                    </div>
                  </div>
                </div>
                <div className="contact-card" id="card4-border">
                  <div className="card-content">
                    <h4>
                      <span>
                        <CI title="Cote d'Ivoire" classNameName="flag-icon" />
                      </span>{" "}
                      {t(`contact:Card4.country`)}
                    </h4>
                    <p className="company-name">{t(`contact:Card4.company`)}</p>
                    <hr className="contact-card-hr" />
                    <div className="phone">
                      <span>
                        <MdPhoneEnabled />
                      </span>
                      <p>{t(`contact:Card4.phone`)}</p>
                    </div>
                    <div className="email">
                      <span>
                        <MdEmail />
                      </span>
                      <p>{t(`contact:Card4.email`)}</p>
                    </div>
                    <div className="contact-person">
                      <span>
                        <MdPerson />
                      </span>
                      <p>{t(`contact:Card4.contact`)}</p>
                    </div>
                  </div>
                </div>
                <div className="contact-card" id="card5-border">
                  <div className="card-content">
                    <h4>
                      <span>
                        <GH title="Ghana" classNameName="flag-icon" />
                      </span>{" "}
                      {t(`contact:Card5.country`)}
                    </h4>
                    <p className="company-name">{t(`contact:Card5.company`)}</p>
                    <hr className="contact-card-hr" />
                    <div className="phone">
                      <span>
                        <MdPhoneEnabled />
                      </span>
                      <p>{t(`contact:Card5.phone`)}</p>
                    </div>
                    <div className="email">
                      <span>
                        <MdEmail />
                      </span>
                      <p>{t(`contact:Card5.email`)}</p>
                    </div>
                    <div className="contact-person">
                      <span>
                        <MdPerson />
                      </span>
                      <p>{t(`contact:Card5.contact`)}</p>
                    </div>
                  </div>
                </div>
                <div className="contact-card" id="card6-border">
                  <div className="card-content">
                    <h4>
                      <span>
                        {" "}
                        <NG title="Nigeria" classNameName="flag-icon" />
                      </span>{" "}
                      {t(`contact:Card6.country`)}
                    </h4>
                    <p className="company-name">{t(`contact:Card6.company`)}</p>
                    <hr className="contact-card-hr" />
                    <div className="phone">
                      <span>
                        <MdPhoneEnabled />
                      </span>
                      <p>{t(`contact:Card6.phone`)}</p>
                    </div>
                    <div className="email">
                      <span>
                        <MdEmail />
                      </span>
                      <p>{t(`contact:Card6.email`)}</p>
                    </div>
                    <div className="contact-person">
                      <span>
                        <MdPerson />
                      </span>
                      <p>{t(`contact:Card6.contact`)}</p>
                    </div>
                  </div>
                </div>
                <div className="contact-card" id="card7-border">
                  <div className="card-content">
                    <h4>
                      <span>
                        {" "}
                        <MZ title="Mozambique" classNameName="flag-icon" />
                      </span>{" "}
                      {t(`contact:Card7.country`)}
                    </h4>
                    <p className="company-name company-no-name">
                      {t(`contact:Card7.company`)}ANHENG ANGOLA -COMÉRCIO GERAL,
                      (SU), LDA.
                    </p>
                    <hr className="contact-card-hr" />
                    <div className="phone">
                      <span>
                        <MdPhoneEnabled />
                      </span>
                      <p>{t(`contact:Card7.phone`)}</p>
                    </div>
                    <div className="email">
                      <span>
                        <MdEmail />
                      </span>
                      <p>{t(`contact:Card7.email`)}</p>
                    </div>
                    <div className="contact-person">
                      <span>
                        <MdPerson />
                      </span>
                      <p>{t(`contact:Card7.contact`)}</p>
                    </div>
                  </div>
                </div>
                <div className="contact-card" id="card8-border">
                  <div className="card-content">
                    <h4>
                      <span>
                        {" "}
                        <ZA title="South Africa" classNameName="flag-icon" />
                      </span>{" "}
                      {t(`contact:Card8.country`)}
                    </h4>
                    <p className="company-name company-no-name">
                      {t(`contact:Card8.company`)}
                      ANHENG ANGOLA -COMÉRCIO GERAL, (SU), LDA.
                    </p>
                    <hr className="contact-card-hr" />
                    <div className="phone">
                      <span>
                        <MdPhoneEnabled />
                      </span>
                      <p>{t(`contact:Card8.phone`)}</p>
                    </div>
                    <div className="email">
                      <span>
                        <MdEmail />
                      </span>
                      <p>{t(`contact:Card8.email`)}</p>
                    </div>
                    <div className="contact-person">
                      <span>
                        <MdPerson size={25} />
                      </span>
                      <p>{t(`contact:Card8.contact`)}</p>
                    </div>
                  </div>
                </div>
                <div className="contact-card" id="card9-border">
                  <div className="card-content">
                    <h4>
                      <span>
                        {" "}
                        <NA title="Namibia" classNameName="flag-icon" />
                      </span>{" "}
                      {t(`contact:Card9.country`)}
                    </h4>
                    <p className="company-name company-no-name">
                      {t(`contact:Card9.company`)}ANHENG ANGOLA -COMÉRCIO GERAL,
                      (SU), LDA.
                    </p>
                    <hr className="contact-card-hr" />
                    <div className="phone">
                      <span>
                        <MdPhoneEnabled />
                      </span>
                      <p>{t(`contact:Card9.phone`)}</p>
                    </div>
                    <div className="email">
                      <span>
                        <MdEmail />
                      </span>
                      <p>{t(`contact:Card9.email`)}</p>
                    </div>
                    <div className="contact-person">
                      <span>
                        <MdPerson />
                      </span>
                      <p>{t(`contact:Card9.contact`)}</p>
                    </div>
                  </div>
                </div>
                <div className="contact-card" id="card10-border">
                  <div className="card-content">
                    <h4>
                      <span>
                        <FcGlobe />
                      </span>{" "}
                      {t(`contact:Card10.country`)}
                    </h4>
                    <p className="company-name company-no-name">
                      {t(`contact:Card10.company`)}ANHENG ANGOLA -COMÉRCIO
                      GERAL, (SU), LDA.
                    </p>
                    <hr className="contact-card-hr" />
                    <div className="phone">
                      <span>
                        <MdPhoneEnabled />
                      </span>
                      <p>{t(`contact:Card10.phone`)}</p>
                    </div>
                    <div className="email">
                      <span>
                        <MdEmail />
                      </span>
                      <p>{t(`contact:Card10.email`)}</p>
                    </div>
                    <div className="contact-person">
                      <span>
                        <MdPerson />
                      </span>
                      <p>{t(`contact:Card10.contact`)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>
    </div>
  );
};
