import React from "react";
import "./Footer.scss"
import FooterLogo from "../../../Images/logo.svg"
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div class="footer-2nd-row">
        <div class="footer-nav">
          {/* <div class="footer-nav-col footer-nav-logo">
            <a href="/index.html">
              <img src={FooterLogo} />
            </a>
            
          </div> */}
          <div className="footer-navitem-wrap">
            <div class="footer-nav-col">
              <span>
                <a href="/">
                  <p class="footer-item link-1">{t('footer.links.1')}</p>
                </a>
              </span>
            </div>
            <div class="footer-nav-col">
              <span>
                <a href="/About">
                  <p class="footer-item">{t('footer.links.2')}</p>
                </a>
              </span>
            </div>
            <div class="footer-nav-col">
              <span>
                <a href="/PlasterBoard">
                  <p class="footer-item">{t('footer.links.3')}</p>
                </a>
              </span>
            </div>
            <div class="footer-nav-col">
              <span>
                <a href="/Contact">
                  <p class="footer-item">{t('footer.links.4')}</p>
                </a>
              </span>
            </div>
          </div>
        </div>

        {/* <div class="footer-social"></div>
        <div class="footer-1st-row">
          <div class="footer-address">
            <div class="footer-address-item">
              <span> {t('footer.addr')}</span>
            </div>

            <div class="footer-address-item">
              <span>{t('footer.email')} : agl_sale@westholdinggroup.com</span>
            </div>
            <div class="footer-address-item">
              <span> {t('footer.phone')} : 930 554 999 / 993 899 999</span>
            </div>
          </div>
        </div>
         */}
         <div class="footer-copyright">
          <span>SLN &copy;2022 </span>
          <span>
            <span class="footer-bar">|</span>{t('footer.copyright')}
          </span>
        </div>

      </div>
    </div>
  );
};
