import React from "react";
import { GypsumPowder } from "../components/product/gypsumPowder/GypsumPowder";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const Powder = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t(`product:gypsumpowder.PageTitle.title`)}</title>
      </Helmet>
      <GypsumPowder />
    </div>
  );
};
