import React from "react";
import GypsumStoneImg from "../../../Images/gypsum-stone-img1.png";
import GypsumStoneImg2 from "../../../Images/gypsum-block-banner-img.png";
import "./GypsumBlock.scss";
import { useTranslation } from "react-i18next";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import Fade from "react-reveal/Fade";

export const GypsumBlock = () => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="gypsum-banner-container">
        <div className="gypsum-banner">
          <div className="gypsum-banner-content">
            <Fade left cascade distance="10%">
              <div>
                <p className="gypsum-banner-text">
                  {t("product:gypsumblocks.banner.title")}
                </p>
                <hr className="gyspum-banner-hr" />
                <h1 className="gypsum-banner-title">
                  {t("product:gypsumblocks.banner.text")}
                </h1>
              </div>
            </Fade>
          </div>
          <img
            className="gypsum-banner-img2"
            src={GypsumStoneImg2}
            alt="gypsum banner"
          />
        </div>
      </section>

      <section className="gypsum-product-sec">
        <div className="gypsum-product-detail">
          {" "}
          <Fade left distance="10%">
            <div className="gypsum-product-detail-content">
              <h2>{t("product:gypsumblocks.product.title")}</h2>
              <p> {t("product:gypsumblocks.product.text")}</p>
              <h4>{t("product:doc.title")}</h4>
              <p className="pdf-link">
                <a
                  className="product-doc--link"
                  href="gypsum_stone.pdf"
                  target="blank"
                >
                  <span className="pdf--icon">
                    <BsFileEarmarkPdfFill color="#CD151D" />
                  </span>
                  <span>{t("product:gypsumboard.spec.doc.text")}</span>
                </a>
              </p>
            </div>
          </Fade>
          <div className="gypsum-product-detail-img-container">
            <Fade right distance="10%">
              <img className="mySlides-img" src={GypsumStoneImg} />
            </Fade>
          </div>
        </div>
      </section>
    </div>
  );
};
