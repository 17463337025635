import React from "react";
import Banner from "../components/common/banner/Banner";
import { Sustainabilitycp } from "../components/about/sustainability/Sustainabilitycp";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export const SustainabilityMain = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet><title>{t(`about:sustainability.PageTitle.title`)}</title></Helmet>
      <Banner
        urlBanner="/SustainBannerImg.jpg"
        titleBanner={t("about:sustainability.banner.title")}
      />
      <Sustainabilitycp />
    </div>
  );
};
