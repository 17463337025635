import { Helmet } from "react-helmet";
import AboutIntro from "../components/about/aboutIntro/AboutIntro";
import MissionVision from "../components/about/missionVision/MissionVision";
import AboutDetail from "../components/about/aboutDetail/AboutDetail";
import PromoAbout from "../components/about/promoAbout/PromoAbout";
import AboutBanner from "../components/about/aboutBanner/AboutBanner";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{t(`about:PageTitle.title`)}</title>
      </Helmet>
      <AboutBanner />
      <AboutIntro />
      <AboutDetail />
      <MissionVision />
      <PromoAbout />
    </div>
  );
}
