import "./AboutDetail.scss";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export default function AboutDetail() {
  const { t } = useTranslation();
  return (
    <div>
      <section class="company-mission-intro">
        <div class="mission-intro-container">
          <div class="col-1 mission-intro">
            <Fade bottom distance="20%">
              <div class="mission-intro-line-container">
                <h3 class="mission-intro-header">
                  {t("about:detail.title.1")}
                </h3>
                <hr class="mission-intro-line" />
                <h1 class="mission-intro-text">{t("about:detail.title.2")}</h1>
              </div>
              <div class="mission-intro-text"></div>
            </Fade>
          </div>

          <div class="col-2 mission-intro">
            <Fade bottom distance="20%">
              <div>
                <p>{t("about:detail.text")}</p>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </div>
  );
}
