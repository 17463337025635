import React from "react";
import "./PromoHome.scss";
import { useTranslation } from "react-i18next";
import HeadShake from "react-reveal/HeadShake";
import Fade from "react-reveal/Fade";

export const PromoHome = () => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="sales-home">
        <div className="sales-home-overlay">
          <div className="sales-home-container">
            <Fade bottom distance="20%">
              <div className="sales-home-text-container ">
                <div className="sales-home-text ">
                  <h4 className="sales-intro-title">{t("home:promo.title")}</h4>
                  <h3 className="sales-intro-text">{t("home:promo.text")}</h3>
                </div>
              </div>
            </Fade>
            <HeadShake delay={1100}>
              <div className="sales-home-btn ">
                <a href="/Contact">
                  <button className="btn1">{t("btn.2")}</button>
                </a>
              </div>
            </HeadShake>
          </div>
        </div>
      </section>
    </div>
  );
};
