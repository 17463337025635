import React from "react";
import { GypsumBoard } from "../components/product/gypsumBoard/GypsumBoard";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const PlasterBoard = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t(`product:gypsumboard.PageTitle.title`)}</title>
      </Helmet>
      <GypsumBoard />
    </div>
  );
};
