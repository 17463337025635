import React from 'react'
import { GypsumBlock } from '../components/product/gypsumBlock/GypsumBlock'
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const PlasterBlock = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
       <title>{t(`product:gypsumblocks.PageTitle.title`)}</title>
      </Helmet>
      <GypsumBlock />
    </div>
  )
}

