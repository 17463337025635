import { Helmet } from "react-helmet";
import ProductHome from "../components/home/productHome/ProductHome";
import AboutHome from "../components/home/aboutHome/AboutHome";
import Whyus from "../components/home/whyus/Whyus";
import { PromoHome } from "../components/home/promoHome/PromoHome";
import BannerHome from "../components/HomeBanner/HomeBanner";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t(`home:PageTitle.title`)}</title>
      </Helmet>
      <BannerHome />
      <ProductHome />
      <AboutHome />
      <Whyus />
      <PromoHome />
    </div>
  );
}
