import React from "react";
import "./Culture.scss";
import CultureImg from "../../../Images/CultureBannerImage.jpg";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

export const Culture = () => {
  const { t } = useTranslation();
  return (
    <div>
      <section className="about-company-culture">
        <div className="about-company-culture-container">
          <div className="culture-content">
            <Fade bottom cascade distance="20%">
              <div>
                <p className="culture-sub-headline"></p>
                <h2>
                  {t("about:culture.title.1")}
                  <span className="culture-header-gray">
                    {" "}
                    | {t("about:culture.title.2")}
                  </span>
                </h2>
                <p className="culture-text">{t("about:culture.text")}</p>
              </div>
            </Fade>
          </div>
          <div className="culture-img">
            <img src={CultureImg} alt="" />
          </div>
        </div>
      </section>
    </div>
  );
};
